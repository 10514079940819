<template>
  <div class="production_wrap">
    <div class = "box">
      <div class="about-title">公司简介</div>
      <div class = "block-line"></div>
      <div class="about-container">
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <b><span style="font-size:16px;">█ 1、公司概况</span></b>
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">大连市国土空间规划设计有限公司注册成立于2019年，由原大连市城市规划设计研究院、原大连市测绘研究院（大连市基础地理信息中心）、原大连市国土资源发展研究中心三家规划、土地、测绘机构部分专业技术资源整合而成的具有经营性职能的国有企业，为大连市城市建设投资集团有限公司下属二级子公司。</span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <b><span style="font-size:16px;">█ 2、组织构架</span></b>
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">空间规划公司党总支下设9个基层党支部，共有党员64人。在上级党组领导下，持续加强和改进党建工作，坚持政治领导、思想领导、组织领导的有机统一。努力推动企业深化改革、提高经营管理水平，加强国有资产监管。</span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">按照公司章程，空间规划公司设董事会、监事会。空间规划公司下设数字规划研究分院、高新分院、城市更新分院等5个分院，18个生产部门和2个行政部门。</span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">空间规划公司托管大连工程咨询中心有限公司（二级公司），下属全资子公司大连市交通设计研究院有限公司（三级公司）。</span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <b><span style="font-size:16px;">█ 3、发展战略</span></b>
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">以国土空间规划业务为核心，以服务国土空间开发保护为主题，突出空间规划公司作为城市建设板块龙头的角色定位，面向两个服务主体，主攻两个转型拓展方向，推进“2+2”发展战略：即服务政府决策，支撑集团发展，大力市场拓展，聚焦数字转型。顺应“数字中国建设整体布局”，实施了“技术立企、创新兴企、人才强企”的科技创新战略。</span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <b><span style="font-size:16px;">█ 4、技术能力</span></b>
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">空间规划公司是大连市委市政府咨询智库成员单位、中国城市规划学会会员单位，辽宁省城市规划学会常务理事单位，辽宁省土地学会常务理事单位，辽宁省城市规划协会常务理事单位，大连市城市更新协会常务理事单位。</span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">公司具备城乡规划甲级，工程咨询甲级，市政道路工程、桥梁工程甲级，土地规划乙级，测绘乙级，排水工程乙级，风景园林工程设计乙级等资质。公司是高新技术企业、雏鹰企业、科技型中小企业、创新型中小企业。空间规划公司拥有软件著作权30项，在核心自主知识产权、科技成果转化能力、成长性指标和人才结构等方面具备较大竞争优势。此外，空间规划公司评有“质量管理体系认证证书”“环境管理体系认证证书”及“职业健康安全管理体系认证证书”。</span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">空间规划公司是大连唯一连续承担地区宏观规划的为政府服务的技术机构，发挥市委市政府咨询智库的重要作用。作为涉密单位，承担着维护国土空间数据安全的重大责任。承担大连援藏。是大连国土空间规划、自然资源、城市建设、数字化建设、测绘地理信息等领域不可或缺的核心技术力量。</span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <b><span style="font-size:16px;">█ 5、托管公司及三级公司</span></b>
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">大连工程咨询中心有限公司成立于2018年11月20日，是大连市城市建设投资集团有限公司的全资二级子公司。2023年5月由集团公司二级子公司--大连市国土空间规划设计有限公司托管，以实现人员、技术、业务等互补，打造设计咨询业务板块。</span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">大连市城市交通设计研究院有限公司，成立于1991年4月，主要从事市政道路、桥梁、排水、风景园林等专业的建设工程设计、技术咨询服务和测绘服务，属于小型工程技术与设计服务行业企业，竞争类国有企业。</span> 
        </p>
      </div>
    </div>
    <CallMe />
    <MobileCallme />
  </div>
</template>

<script>
import CallMe from '@/components/callme/index'
import MobileCallme from '@/components/callme/MobileCallme.vue'
export default {
  data() {
    return {
    }
  },
  components: {
    CallMe,
    MobileCallme,
  },
}
</script>

<style lang="scss" scoped>
.production_wrap {
  padding-top: 200px;
  width: 100%;
}
</style>